import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Router from 'app/Router'
import ErrorBoundary from 'app/ErrorBoundary'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Router />
    </React.StrictMode>
  </ErrorBoundary>
)
