import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ENABLE_SERVICES_PAGE } from './config/web-config'
import Fallback from './components/Fallback'

const Home = lazy(async () => await import('app/pages/Home'))
const Contact = lazy(async () => await import('app/pages/Contact'))
const Services = lazy(async () => await import('app/pages/Services'))

const ParappaError = lazy(async () => await import('app/components/ParappaError'))

function Router (): JSX.Element {
  return (
    <Suspense fallback={<Fallback />}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          {ENABLE_SERVICES_PAGE && <Route path='/services' element={<Services />} />}

          {/* 404 */}
          <Route
            path='*' element={
              <ParappaError
                customHeaderText='Page not found'
                customBodyText='The page you are looking for does not exist.'
              />
          }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default React.memo(Router)
