import React from 'react'
import ParappaCMS from 'app/assets/parappa-cms-logo.svg'

function Fallback (): JSX.Element | null {
  const delay = 200 // ms
  const [shouldShow, setShouldShow] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => setShouldShow(true), delay)

    return () => clearTimeout(timeout)
  }, [delay])

  return shouldShow
    ? (
      <div className='absolute px-4 py-2 rounded-md bottom-5 right-5 bg-stone-100' data-testid='fallback-element'>
        <div className='flex space-x-2'>
          <img src={ParappaCMS} alt='Parappa CMS' className='w-6 h-auto' />
          <p className='text-base'>Loading...</p>
        </div>
      </div>
      )
    : null
}

export default React.memo(Fallback)
