import React, { lazy, Suspense, Component, ErrorInfo, ReactNode } from 'react'
import Fallback from 'app/components/Fallback'

const ParappaError = lazy(async () => await import('app/components/ParappaError'))

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = { hasError: false }

  static getDerivedStateFromError (error: Error): State {
    return { hasError: true, error: error }
  }

  public componentDidCatch (error: Error, errorInfo: ErrorInfo): void {
    this.setState({ errorInfo })
    console.error('Parappa CMS error:', error, errorInfo)
  }

  public render (): JSX.Element | React.ReactNode {
    if (this.state.hasError) {
      return (
        <Suspense fallback={<Fallback />}>
          <ParappaError
            error={this.state.error}
            errorInfo={this.state.errorInfo}
            customHeaderText='Error rendering this page'
            customBodyText='Sorry, something went wrong while attempting to render this page. If you are not the website owner, try again later.'
          />
        </Suspense>
      )
    }

    return this.props.children
  }
}

export default React.memo(ErrorBoundary)
